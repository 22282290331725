<template>
  <BasePending v-if="pending" />
  <Accordion v-else :activeIndex="[0, 1]" :multiple="true">
    <AccordionTab header="Ajouter un Tarif">
      <Create />
    </AccordionTab>
    <AccordionTab header="Tarifs">
      <DataTable
        class="custom-datatable p-datatable-sm"
        :value="rates"
        :paginator="true"
        :rows="rows"
        dataKey="id"
        filterDisplay="row"
        :loading="pending"
        ref="dt"
      >
        <template #empty> Aucun article trouvé. </template>
        <template #loading> Chargement des données. </template>
        <template #footer>
          <div class="flex align-items-center justify-content-between">
            <Button
              label="Exporter (.csv)"
              icon="pi pi-external-link"
              @click="exportCSV($event)"
            ></Button>
            <div>
              <label class="mr-2">Afficher:</label>
              <Dropdown :options="[10, 25, 50, 100]" v-model="rows" />
            </div>
          </div>
        </template>
        <Column header="Tarif" style="text-align: center; width: 64px">
          <template #body="{ data }">
            <span class="uppercase">{{ data.id }}</span>
          </template>
        </Column>
        <Column header="Détails" class="col">
          <template #body="{ data }">
            <InputText
              v-model="data.name"
              :disabled="!data.edit"
              class="inplace"
            />
          </template>
        </Column>
        <Column header="Actions" class="col-1 justify-content-end">
          <template #body="{ data }">
            <Button
              :icon="!data.edit ? 'pi pi-pencil' : 'pi pi-check'"
              :class="
                !data.edit
                  ? 'p-button-text p-button-secondary'
                  : 'p-button-success'
              "
              @click="onEdit(data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-text p-button-danger"
              @click="onRemove($event, data)"
            />
          </template>
        </Column>
      </DataTable>
    </AccordionTab>
  </Accordion>
</template>

<script>
import { ref } from "vue";
import useDatabase from "../../../composables/useDatabase";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

import Create from "./_Create";

export default {
  components: { Create },
  setup() {
    const confirm = useConfirm();
    const toast = useToast();

    const { getAll, removeOne } = useDatabase("mercurial/rates");
    const { docs: rates, error, pending } = getAll();

    const dt = ref();
    const rows = ref(10);

    const exportCSV = () => dt.value.exportCSV();

    const onEdit = async (data) => {
      data.edit = !data.edit;
    };

    const onRemove = (event, data) => {
      confirm.require({
        target: event.currentTarget,
        message: `Êtes-vous certain de vouloir supprimer le tarif ${data.name} ?`,
        acceptClass: "p-button-danger",
        acceptLabel: "Supprimer",
        rejectClass: "p-button-text p-button-secondary",
        rejectLabel: "Non",
        accept: async () => {
          await removeOne(data.id);
          toast.add({
            severity: "info",
            header: "Informations",
            detail: `La tarif ${data.name} à été supprimé !`,
            life: 2500,
          });
        },
      });
    };

    return { rates, error, pending, dt, rows, exportCSV, onEdit, onRemove };
  },
};
</script>

<style lang="scss" scoped></style>

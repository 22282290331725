<template>
  <BasePending v-if="pending" />
  <form v-else id="form" class="p-field p-2" @submit.prevent="onSubmit">
    <div class="p-fluid col-2"></div>
    <div class="p-fluid col">
      <label for="name">Nom</label>
      <InputText id="name" v-model="name" />
    </div>
    <div class="flex col-12 justify-content-end">
      <Button
        icon="pi pi-times"
        class="p-button-text p-button-secondary mr-2"
        label="Effacer"
        @click="onClear"
        :disabled="!name"
      />
      <Button
        type="submit"
        icon="pi pi-plus"
        class="p-button-success"
        label="Ajouter"
        :disabled="!name"
      />
    </div>
  </form>
</template>

<script>
import useDatabase from "../../../composables/useDatabase";
import { reactive, toRefs } from "vue";
import { useToast } from "primevue/usetoast";

export default {
  setup() {
    const toast = useToast();
    const { getAll, updateOne } = useDatabase("mercurial/rates");
    const { docs: rates, pending } = getAll();

    const newRate = reactive({
      name: "",
    });

    const onClear = () => {
      newRate.name = "";
    };

    const onSubmit = async () => {
      await updateOne(`t${rates.value.length.toString()}`, { ...newRate });
      onClear();
      toast.add({
        severity: "success",
        detail: "Nouveau tarif ajouté !",
        life: 2500,
      });
    };

    return { onClear, onSubmit, ...toRefs(newRate), pending };
  },
};
</script>

<style lang="scss" scoped></style>
